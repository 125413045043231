import { types, applySnapshot, Instance, SnapshotOut } from 'mobx-state-tree'
import TeamStore, { TeamStoreType } from './TeamStore';

const saveToLocalStorage = (self: any) => {
  localStorage.setItem('app', JSON.stringify(self))
}

const AppModel = {
  teams: types.array(TeamStore),
}

const Actions = (self: any) => {
  const afterCreate = () => {
    self.setInitialAppStore()
  }

  const setInitialAppStore = async () => {
    const AppStore = await self.getAppStoreFromLocalStorage()

    if (AppStore !== null) {
      applySnapshot(self, AppStore)
    }
  }

  const getAppStoreFromLocalStorage = async () => {
    try {
      const AppStore = localStorage.getItem('app')
      if (!AppStore) {
        return null
      }
      const cachedAppStore = JSON.parse(AppStore)
      return cachedAppStore
    } catch (e) {
      return null
    }
  }

  const setTeams = (teams: TeamStoreType[]) => {
    self.teams = teams
    saveToLocalStorage(self)
  }

  return {
    afterCreate,
    setInitialAppStore,
    getAppStoreFromLocalStorage,
    setTeams,
  }
}

const AppStore = types.model('App', AppModel).actions(Actions)

export default AppStore

export type AppStoreType = Instance<typeof AppStore>
export type AppStoreSnapshotType = SnapshotOut<typeof AppStore>
