import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Grid, Typography, colors } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneIcon from '@mui/icons-material/Done';
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBarComponent from '../components/AppBar';
import { observer } from 'mobx-react';
import moment from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    flexDirection: 'column',
    minHeight: '100vh'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: theme.spacing(6),
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  text: {
    color: theme.palette.text.primary,
  },
  yellowText: {
    color: colors.yellow[800],
  },
  secondaryText: {
    color: theme.palette.text.secondary,
  },
  unselected: {
    backgroundColor: '#000000',
  },
  selected: {
    backgroundColor: colors.green[600],
  },
  bottomNavContainer: {
    position: 'sticky',
    bottom: 0,
    paddingBottom: 'env(safe-area-inset-bottom, 20px)',
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  divider: {
    backgroundColor: theme.palette.text.primary
  },
  calendar: {
    width: '100%'
  }
}));

const loadInitialRefTimes = () => {
  const refTimes = localStorage.getItem('refTimes')
  if (refTimes) {
    return JSON.parse(refTimes)
  }
  return []
}

const AddRefTimesPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedTimes, setSelectedTimes] = useState<{ date: string, court: string }[]>(loadInitialRefTimes())

  const getNextVolleyballDays = (count: number) => {
    const today = new Date();
    const weekdays = [];

    // Function to check if a date is Monday, Tuesday, Wednesday, or Thursday
    function isDesiredWeekday(date: Date) {
      const day = date.getDay();
      return day >= 1 && day <= 4; // Monday to Thursday (0 is Sunday)
    }

    let c = 0;
    while (c < count) {
      if (isDesiredWeekday(today)) {
        weekdays.push(new Date(today)); // Add a new instance to avoid modifying the original date
        c++;
      }
      today.setDate(today.getDate() + 1); // Move to the next day
    }

    return weekdays;
  }

  const toggleSelectedTime = (court: string, date: string) => {
    if (isSelected(court, date)) {
      setSelectedTimes(selectedTimes.filter((t) => t.date !== date))
    } else if (selectedTimes.some(t => t.date === date)) {
      const newTimes = selectedTimes.filter((t) => t.date !== date)
      setSelectedTimes([...newTimes, { court, date }])
    } else {
      setSelectedTimes([...selectedTimes, { court, date }])
    }
  }

  const isSelected = (court: string, date: string) => {
    return selectedTimes.some(t => t.date === date && t.court === court)
  }

  const times = [['5:25pm', '17:25'], ['6:00pm', '18:00'], ['6:35pm', '18:35'], ['7:10pm', '19:10'], ['7:45pm', '19:45'], ['8:20pm', '20:20'], ['8:55pm', '20:55'], ['9:30pm', '21:30'], ['10:05pm', '22:05']]
  const courts = ['Cottlesloe', 'Surfers', 'St Kilda', 'Manly', 'Glenelg', 'Darwin', 'Torquay']
  const dates = getNextVolleyballDays(4)
  return (
    <div className={classes.root}>
      <AppBarComponent
        LeftIcon={<ArrowBackIcon />}
        onLeftIconClick={() => navigate('/')}
        RightIcon={<DoneIcon />}
        onRightIconClick={() => {
          // Remove any times in the past
          console.log(selectedTimes)
          const now = moment.tz('Australia/Melbourne')
          const timesToSave = selectedTimes.filter((t) => {
            return moment.tz(t.date, 'Australia/Melbourne').isAfter(now)
          })
          localStorage.setItem('refTimes', JSON.stringify(timesToSave))
          navigate('/')
        }}
      >
        Add Referee Times
      </AppBarComponent>
      <main className={classes.content}>
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {dates.map((date) => (
            <Grid key={moment.tz(date, 'Australia/Melbourne').format('YYYY-MM-DD')} item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{moment.tz(date, 'Australia/Melbourne').format('dddd, MMMM Do')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {times.map((time) => (
                      <Grid item xs={12}>
                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                          <Grid item xs={12} style={{ textAlign: 'center' }}><Typography className={classes.primaryText} variant="h6">{time[0]}</Typography></Grid>
                          {courts.map((court) => (
                            <Grid item xs={4} style={{ textAlign: 'center' }}>
                              <Card
                                className={isSelected(court, `${moment.tz(date, 'Australia/Melbourne').format('YYYY-MM-DD')}T${time[1]}`) ? classes.selected : classes.unselected}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  e.preventDefault()
                                  toggleSelectedTime(court, `${moment.tz(date, 'Australia/Melbourne').format('YYYY-MM-DD')}T${time[1]}`)
                                }}
                              >
                                <CardContent>
                                  <Typography>{court}</Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))
          }
        </Grid >
      </main>
    </div>
  );
};

export default observer(AddRefTimesPage);
