import { useQuery } from '@tanstack/react-query';
import { searchTeam } from "../helpers/queries";

const useSearchQuery = (query: string) => {
  return useQuery({
    queryKey: ['search', query],
    queryFn: async () => {
      const searchResults = await searchTeam(query)
      return searchResults
    },
    staleTime: 1000 * 60 * 60,
    enabled: query.length > 2
  })
}

export default useSearchQuery
