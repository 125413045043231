import { useQuery } from '@tanstack/react-query';
import { useStores } from './useStores';
import { searchTeam } from '../helpers/queries';
import TeamStore from '../stores/TeamStore';

const useTeamQuery = (teamId?: string, teamName?: string) => {
  const { app } = useStores()

  return useQuery({
    queryKey: ['team', teamId],
    queryFn: async () => {
      let team = app.teams.find(t => `${t.team.teamId}` === teamId)

      if (!team && teamName) {
        const searchResults = await searchTeam(teamName)
        team = TeamStore.create({ team: searchResults[0] })
      }
      await Promise.all([
        team?.fetchFixture(),
        team?.fetchLadder(),
        team?.fetchResults()
      ]);
      return team
    },
    staleTime: 1000 * 60 * 10,
    enabled: true
  });
}

export default useTeamQuery
