import { StrictMode, useMemo } from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './routes/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SearchTeamsPage from './routes/SearchTeamsPage';
import TeamPage from './routes/TeamPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'mobx-react'
import AppStore from './stores/AppStore';
import HeadToHeadPage from './routes/HeadToHeadPage';
import AddRefTimesPage from './routes/AddRefTimesPage';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

const queryClient = new QueryClient()

const theme = createTheme(adaptV4Theme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.yellow[800], // Customize the primary color
    },
    secondary: {
      main: '#000000', // Customize the secondary color
    },
  },
  typography: {
    fontFamily: 'Arial', // Customize the default font family
    fontSize: 14, // Customize the default font size
  },
}));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: 'search',
    element: <SearchTeamsPage />,
  },
  {
    path: 'teams/:teamId',
    element: <TeamPage />,
  },
  {
    path: 'teams/:teamId/h2h/:opponentName',
    element: <HeadToHeadPage />
  },
  {
    path: 'ref/add-times',
    element: <AddRefTimesPage />
  }
]);

const container = document.getElementById('root');

const Root = () => {
  const store = useMemo(() => AppStore.create(), [])
  return (
    <StrictMode>
      <Provider app={store} {...store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </StrictMode>
  )
}

render(<Root />, container);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

