import { useQuery } from '@tanstack/react-query';

const useHourlyWeatherQuery = () => {
  return useQuery({
    queryKey: ['hourlyWeather'],
    queryFn: async () => {
      const response = await fetch('https://api.weather.bom.gov.au/v1/locations/r1nwvh/forecasts/hourly')
      const data = await response.json()
      return data.data
    },

    staleTime: 1000 * 60 * 60
  })
}

export default useHourlyWeatherQuery