import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppBarComponent from '../components/AppBar';
import TeamStore from '../stores/TeamStore';
import { observer } from 'mobx-react';
import useSearchQuery from '../hooks/useSearchQuery';
import { useStores } from '../hooks/useStores';
import { ISearchTeamsReturnValue } from '../helpers/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    minHeight: '100%'
  },
  searchField: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  searchButton: {
    marginBottom: theme.spacing(2),
  },
  card: {
    margin: '10px',
    borderRadius: '15px',
    textAlign: 'center',
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative'
  },
  text: {
    color: theme.palette.text.primary,
  }
}));

const SearchTeamPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { app } = useStores()
  const [searchTerm, setSearchTerm] = useState('');

  const { data: searchedTeams, isFetching } = useSearchQuery(searchTerm);

  const searchInputRef = useRef<HTMLInputElement>();

  const handleSearch = async () => {
    // If the input field is currently focused, blur it to close the keyboard
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const onSearchCardClick = async (team: ISearchTeamsReturnValue[0]) => {
    // Add team
    app.setTeams([...app.teams, TeamStore.create({ team })]);

    navigate('/');
  }

  const renderContent = () => {
    if (searchTerm.length < 3) {
      return (
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.text}>Please enter at least 3 characters</Typography>
        </Grid>
      )
    }

    if (isFetching) {
      return (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )
    }
    if (!searchedTeams?.length) {
      return (
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.text}>No teams found</Typography>
        </Grid>
      )
    }

    return searchedTeams!.map((team) => (
      <Grid item key={team.teamId} xs={12}>
        <Card className={classes.card} onClick={() => onSearchCardClick(team)}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>{team.teamName}</Typography>
            <Typography variant="body1">{`${team.gradeName}${team.sportName.toLowerCase() === 'beach rebound' ? ' rebound' : ''}`}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  }

  return (
    <div className={classes.root}>
      <AppBarComponent
        LeftIcon={<ArrowBackIcon />}
        onLeftIconClick={() => navigate('/')}
      >
        Find a team
      </AppBarComponent>
      <main className={classes.content}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          className={classes.searchField}
          inputProps={{ inputMode: 'search' }}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
              e.preventDefault();
            }
          }}
          inputRef={searchInputRef}
        />
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {renderContent()}
        </Grid>
      </main>
    </div>
  );
};

export default observer(SearchTeamPage);
