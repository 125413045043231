import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { useStores } from '../hooks/useStores';
import { TeamStoreType } from '../stores/TeamStore';

interface IProps {
  team: TeamStoreType
}

const TeamCardComponent = (props: IProps) => {
  const { team } = props
  const { app } = useStores()

  const handleCloseClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    app.setTeams(app.teams.filter((t) => t.team.teamId !== team.team.teamId))
  };

  return (
    <Card
      style={{
        margin: '10px',
        borderRadius: '15px',
        textAlign: 'center',
        minHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      <IconButton
        onClick={handleCloseClick}
        style={{ position: 'absolute', right: 0, top: 0 }}
        size="large">
        <CloseIcon />
      </IconButton>
      <Link to={`teams/${team.team.teamId}`} state={{ teamName: team.team.teamName }} style={{ textDecoration: 'none', color: 'inherit' }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>{team.team.teamName}</Typography>
          <Typography variant="body1">{`${team.team.gradeName}${team.team.sportName.toLowerCase() === 'beach rebound' ? ' rebound' : ''}`}</Typography>
        </CardContent>
      </Link>
    </Card>
  );
};

export default observer(TeamCardComponent);
