import React from 'react';
import { AppBar, IconButton, Toolbar, Typography, colors } from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed'
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    color: colors.yellow[800]
  },
  titleMargin: {
    marginLeft: theme.spacing(-2)
  },
  titleMarginNoRightIcon: {
    marginLeft: theme.spacing(-8),
  },
  leftButton: {
    marginRight: theme.spacing(2)
  },
}));

interface IProps {
  LeftIcon?: React.ReactNode;
  RightIcon?: React.ReactNode;
  onLeftIconClick?: () => void;
  onRightIconClick?: () => void;
  children: string
}

const AppBarComponent = (props: IProps) => {
  const { LeftIcon, RightIcon, onLeftIconClick, onRightIconClick, children } = props
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {LeftIcon && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onLeftIconClick}
            className={classes.leftButton}
            size="large">
            {LeftIcon}
          </IconButton>
        )}
        <Typography variant="h6" className={`${classes.title} ${RightIcon ? classes.titleMargin : classes.titleMarginNoRightIcon}`}>{children}</Typography>
        {RightIcon && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={onRightIconClick}
            size="large">
            {RightIcon}
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent;