import { useQuery } from '@tanstack/react-query';
import { TeamStoreType } from '../stores/TeamStore';
import moment from 'moment-timezone'

interface IUpcomingMatch {
  date?: string
  teamName: string
  round?: string
  opponentName?: string
  type: string
  court?: string
  temp?: number
  temp_feels_like?: number
}

interface IUpcomingMatches {
  [round: string]: IUpcomingMatch[]
}

const useUpcomingMatchesQuery = (teams: TeamStoreType[]) => {
  return useQuery({
    queryKey: ['upcoming', ...teams.map(team => team.team.teamId)],
    queryFn: async () => {
      await Promise.all(teams.map(async (team) => {
        await team.fetchFixture()
      }))

      return teams
        .map(team => team.getFutureGames().map(game => ({ ...game, type: 'match', teamName: team.team.teamName })))
        .flat() as { date: string, teamName: string, round?: string, opponentName?: string, type: string }[]
    },

    staleTime: 1000 * 60 * 60
  })
}

const getRefTimes = (): any[] => {
  const refTimes = localStorage.getItem('refTimes')
  if (!refTimes) return []
  const times = JSON.parse(refTimes)

  // Remove any times before today
  return times.filter((time: any) => moment.tz(time.date, 'Australia/Melbourne').isAfter(moment.tz('Australia/Melbourne')))

}

export const sortAndGroupUpcomingMatches = (matches: IUpcomingMatch[]): IUpcomingMatches => {
  const refTimes = getRefTimes()

  const upcomingWithRefs = matches.map(m => ({ ...m, type: 'match' })).concat([...refTimes.map((refTime: any) => ({ ...refTime, type: 'ref' }))])

  const sortedUpcoming = upcomingWithRefs.sort((a, b) => {
    if (a && b) {
      return a.date!.localeCompare(b.date!)
    }
    return 0
  })

  // Group by round
  const groupedByRound = sortedUpcoming.reduce((acc: any, game) => {
    let round: string | undefined = game.round
    if (game.type === 'ref') {
      // Find matching round from upcoming games using date. Must be in the same week to be considered the same round
      const matchingGame = sortedUpcoming.find((upcomingGame) => {
        const gameDate = moment.tz(game.date, 'Australia/Melbourne')
        const upcomingGameDate = moment.tz(upcomingGame.date, 'Australia/Melbourne')
        return gameDate.isSame(upcomingGameDate, 'week') && upcomingGame.type === 'match'
      })
      round = matchingGame?.round
    }

    if (!round) {
      round = '-'
    }
    if (!acc[round]) {
      acc[round] = []
    }
    acc[round!].push(game)
    return acc
  }, {})

  return groupedByRound
}

export default useUpcomingMatchesQuery
